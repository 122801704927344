import React from "react";
import ReadMoreBtn from "../../Elements/ReadMoreBtn";
import HeaderPart from "../../Elements/HeaderPart";
import IntegrationImg from "./IntegrationImg";
import Carousel from "./Carousel";

const MainIntegration = () => {
    const header = (
        <b className="text-white">
            INTEGRATION WITH  <span className="text-joinet-blue">ANY <br/> VPN-GATEWAY</span>
        </b>)

    const description = (
        <>
            Joinet enhances corporate VPN capabilities by tightly integrating with
            VPN Gateways and other corporate infrastructure systems to consolidate
            information from various sources for making decisions about authentication
            and access settings.
        </>
    )

    const description_short = (
        <>
            Joinet enhances corporate VPN capabilities by tightly integrating with
            VPN Gateways and other corporate infrastructure systems.
        </>
    )

    return (
        <>

            <HeaderPart header={header} description={description} description_short={description_short}/>


            <div className="row pt-lg-4 justify-content-center mx-0 px-0">
                <div className="col-2 d-lg-inline d-none order-lg-first"></div>
                <div className="col-lg-4 col-11 my-3 d-flex justify-content-center order-last order-lg-first">
                    <div className="col-lg-8 col-11 align-content-center ">
                        <div>
                            <h4 className="text-white">1. RADIUS Server</h4>
                            <p className="text-secondary text-joinet d-inline d-lg-none" style={{fontSize: 12}}>
                                Integration with VPN-Gateway using RADIUS protocol for
                                authentication, authorization, and session management.
                            </p>
                            <p className="text-secondary text-joinet d-none d-lg-inline" style={{fontSize: 14}}>
                                Integration with VPN-Gateway using RADIUS protocol for
                                authentication, authorization, and session management.
                            </p>
                        </div>

                        <br/>
                        <div>
                            <h4 className="text-white">2. Joinet Plugin</h4>
                            <p className="text-secondary text-joinet d-inline d-lg-none" style={{fontSize: 12}}>
                                Integration with VPN-Gateway using plugin developed for
                                certain VPN-Gateway to connect it with Joinet Internal Portal.
                            </p>
                            <p className="text-secondary text-joinet d-none d-lg-inline" style={{fontSize: 14}}>
                                Integration with VPN-Gateway using plugin developed for
                                certain VPN-Gateway to connect it with Joinet Internal Portal.
                            </p>
                        </div>

                    </div>

                </div>
                <div className="col-lg-4 col-10 pt-0 pb-5  pt-4 mx-auto">
                    <img src={require("../../../Files/Integration_1.png")} className="mx-auto d-block img-fluid"/>
                </div>
                <div className="col-2 d-lg-inline d-none"></div>
            </div>
            <div className="col-12 col-lg-6 mx-auto opacity-50 py-5">
                <Carousel />
            </div>
        </>
    );
};

export default MainIntegration;

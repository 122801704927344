import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Pages/Footer";


function App() {
  return (
      <>
        <Navbar/>
         <Outlet/>
          <Footer/>
      </>
  );
}

export default App;

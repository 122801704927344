import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    ru: {
        translation: {
            "Solutions": "Решения",
            "Pricing": "Лицензии",
            "About": "О Компании",
            "Contacts": "Контакты",
            "Request Demo": "Запросить Демо",
            "Comprehensive": "Комплексный",
            "Approach": "Подход",
            "To": "К",
            "Management": "Управлению",
            "Remote": "Удаленным",
            "Access": "Доступом",
            "Licenses": "Лицензии",
            "Consultation": "Консультация",
            "Submit Request": "Отправить запрос",
            "Comment": "Комментарий",
            "Name": "Имя",
            "Organization Name": "Наименование Организации",
            "Email": "Электронная почта",
            "Phone": "Номер Телефона",
            "We will not use your contacts to send any advertising materials": "Контакты не будут использованы для рассылки рекламных материалов",
            "Fill out the form, and our specialists will contact you": "Заполните форму и наши специалисты свяжутся с вами",
            "to provide consultation on Joinet's services and products": "для консультации по продуктам и услугам joinet",
            "": "",


        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
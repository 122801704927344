import React from "react";
import {Link} from "react-router-dom";
import {CiCircleInfo} from "react-icons/ci";

const HeaderPart = ({header, description, header_class, description_short}) => {
    return (
        <div className="mx-lg-5 px-3 pt-5 mt-2 mt-md-4 pb-3">
            <div className=" col-11 d-flex align-items-center justify-content-center mx-auto">
                <b className={`ps-4 h2 text-joinet-oswald my-0 py-0 text-center ${header_class}`}>
                    {header}
                </b>
                <Link to="#" className="text-secondary"><CiCircleInfo size={22} className="ml-2 ms-2 mt-md-1" /></Link>
            </div>
            { description &&
                <>
                    <div className="col-5 mx-auto pt-4 text-center d-none d-lg-flex">
                        <p className="text-secondary text-joinet" style={{fontSize: 14}}>
                            {description}
                        </p>
                    </div>
                    <div className="col-11 mx-auto pt-4 text-center d-flex d-lg-none">
                        <p className="text-secondary text-joinet" style={{fontSize: 12}}>
                            {description_short}
                        </p>
                    </div>
                </>

            }



        </div>
    );
};

export default HeaderPart;

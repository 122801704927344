import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider, Navigate} from 'react-router-dom';
import App from './App';
import Main from "./Pages/Main";
import { lazy, Suspense } from 'react';

const Licenses = lazy(() => import('./Pages/Licenses/Licenses'));
const Contacts = lazy(() => import('./Pages/Contacts'));
const About = lazy(() => import('./Pages/About'));
const Request = lazy(() => import('./Pages/Request'));
const Platform = lazy(() => import('./Pages/Platform/Platform'));
const Solutions = lazy(() => import('./Pages/Products'));


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        //errorElement: <Error />,
        children: [
            {
                index: true,
                path: "/",
                element: (<Main/>),
            },
            {
                path: "/licenses",
                element: (<Suspense fallback={<div>Loading...</div>}><Licenses/></Suspense>),
            },
            {
                path: "/contacts",
                element: (<Suspense fallback={<div>Loading...</div>}><Contacts/></Suspense>),
            },
            {
                path: "/platform",
                element: (<Suspense fallback={<div>Loading...</div>}><Platform/></Suspense>),
            },
            {
                path: "/solutions",
                element: (<Suspense fallback={<div>Loading...</div>}><Solutions/></Suspense>),
            },
            {
                path: "/about",
                element: (<Suspense fallback={<div>Loading...</div>}><About/></Suspense>),
            },
            {
                path: "/request",
                element: (<Suspense fallback={<div>Loading...</div>}><Request/></Suspense>),
            },
            {
                path: "*",
                element: (<Navigate to="/" replace />),
            },
        ]
    },
])


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

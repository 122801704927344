import React from "react";
import HeaderPart from "../../Elements/HeaderPart";
import RequestProperty from "./RequestsProperty";

const MainRequests = () => {
    const header = (<>ACCESS REQUEST & APPROVAL</>)

    const description = (
        <>
            Joinet offers a complete set of features to manage the
            request and approval process for changes in ACL rules,
            access group memberships, and authorized connection locations.
        </>)
    const description_short = (
        <>
            Joinet offers a complete set of features to manage the
            request and approval process for changes in ACL rules,
            access group memberships, and authorized connection locations.
        </>
    )
    const description1 = "Joinet notify users about all the changes in requests and notify responsible " +
                                "users if they require to approve requests. All the notifications can be adapted " +
                                "to corporate style."
    const description2 = "Joinet notify users about all the changes in requests and notify responsible " +
                                "users if they require to approve requests. All the notifications can be adapted " +
                                "to corporate style."
    const description3 = "Joinet can be integrated with directory services (AD, OpenLDAP, etc.) to synchronize " +
                                "various changes such as group creation, membership changes, and more in both directions."

    return (
        <>
            <HeaderPart header={header} description={description} header_class={"text-joinet-dark"} description_short={description_short}/>

            <div className="col-lg-7 col-11 mx-auto pt-0 pb-5">
                <img src={require("../../../Files/Requests_2.png")} className="mx-auto d-block img-fluid"/>
            </div>

            <div className="row col-11 col-lg-10 mx-auto order-lg-first ">
                <RequestProperty photo={require("../../../Files/Requests_6.png")} header="Adjustable Approval Workflow" description={description1} />
                <RequestProperty photo={require("../../../Files/Requests_7.png")} header="User Email Notification" description={description2} />
                <RequestProperty photo={require("../../../Files/Requests_8.png")} header="Directory Service Integration" description={description3} />
            </div>
            <div className="col-lg-5 col-11 mx-auto pt-3 pb-3 mb-4 px-4 px-lg-4 ">
                <img src={require("../../../Files/Requests_1.png")} className="mx-auto d-block img-fluid"/>
            </div>
        </>
    );
};

export default MainRequests;

import React from "react";


const LocationProperty = ({photo, header, description}) => {

    return (
        <div className="col-12 row">
            <div className="col-2 col-lg-3 mx-auto mx-0 px-0 ">
                <img  src={require("../../../Files/Location_2.png")} className="mx-auto d-block img-fluid px-lg-2" />
            </div>
            <div className="col-9 mx-auto  mx-0 px-0">
                <h5>{header}</h5>
                <p className="text-joinet text-dark opacity-75" style={{fontSize: 12 }}>
                    {description}
                </p>
            </div>
        </div>
    );
};

export default LocationProperty;


import React from "react";
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <div className="container pt-5 pb-2 mt-auto">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 border-top">
                <img src={require("../Files/logo.png")} className="mx-4" alt="..." style={{width: 40}}/>
                <p className="col-md-3 mb-0 text-white">© {new Date().getFullYear()} Joinet, Inc</p>
                <a href="/"
                   className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                    <svg className="bi me-2" width="40" height="32">
                    </svg>
                </a>

                <ul className="nav col-md-4 justify-content-end" hidden={true}>
                    <li className="nav-item"><NavLink to="/" className="nav-link px-2 text-white">Home</NavLink></li>
                    <li className="nav-item"><NavLink to="/products" className="nav-link px-2 text-white">Solutions</NavLink></li>
                    <li className="nav-item"><NavLink to="/licenses" className="nav-link px-2 text-white">Pricing</NavLink></li>
                    <li className="nav-item"><NavLink to="/about" className="nav-link px-2 text-white">About</NavLink></li>
                    <li className="nav-item"><NavLink to="/contacts" className="nav-link px-2 text-white">Contacts</NavLink></li>
                </ul>
            </footer>
        </div>
    );
};

export default Footer;




import React from "react";
import {useEffect, useState} from "react";
import { CiCircleInfo } from "react-icons/ci";
import {Link} from "react-router-dom";
import HeaderPart from "../Elements/HeaderPart";
const RequestForm = () => {
    const divClasses = "col-lg-4 col-md-5 col-11 mx-auto py-4 px-3 "
    const imgClasses = "mx-auto d-block img-fluid mx-lg-4 mx-md-3 mx-0 hover-zoom"
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect( () => {
        console.log("Width: " + window.innerWidth)
        if (window.innerWidth < 721) {
            setIsCollapsed(false)
        }
    }, [])
    const header = (
        <b className="text-joinet-dark">
            ADDITIONAL SECURITY FEATURES
        </b>)

    const description = (
        <>
            Joinet includes set of security features that help to enhance security of
            corporate remote access while increasing it's convenience. Joinet's security tools and features
            allow to involve all remote staff into security processes.
        </>
    )
    const description_short = (
        <>
            Joinet includes set of security features that help to echance security of
            corporate remote access while increasing it's convinience.
        </>
    )
    return (
        <>
            <HeaderPart header={header} description={description} description_short={description_short}/>

            <div className="row mb-3 px-0 mx-0">
                <div className={divClasses}>
                    <img src={require("../../Files/Security_1.png")} className={imgClasses}/>
                </div>
                <div className={divClasses}>
                    <img src={require("../../Files/Security_2.png")} className={imgClasses}/>
                </div>
            </div>
            <div className={`collapse ${isCollapsed ? 'show' : ''}`} id="collapseSecurity">
                <div className="row mb-3 px-0 mx-0">
                    <div className={divClasses}>
                        <img src={require("../../Files/Security_3.png")} className={imgClasses}/>
                    </div>
                    <div className={divClasses}>
                        <img src={require("../../Files/Security_4.png")} className={imgClasses}/>
                    </div>
                </div>
                <div className="row mb-3 px-0 mx-0">
                    <div className={divClasses}>
                        <img src={require("../../Files/Security_5.png")} className={imgClasses}/>
                    </div>
                    <div className={divClasses}>
                        <img src={require("../../Files/Security_6.png")} className={imgClasses}/>
                    </div>
                </div>
            </div>
            { !isCollapsed &&
                <div className="row justify-content-center col-xl-3 col-lg-4 col-10 mx-auto my-lg-3 mb-3">
                    <button className={`btn btn-sm btn-outline-dark col-4 py-0 mx-3 rounded rounded-5 opacity-75`}
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSecurity"
                            aria-expanded="false"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            aria-controls="collapseSecurity">
                        Show more
                    </button>
                </div>
            }
        </>
    );
};

export default RequestForm;

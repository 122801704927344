import React from "react";
import ReadMoreBtn from "../../Elements/ReadMoreBtn";
import HeaderPart from "../../Elements/HeaderPart";
import LocationProperty from "./LocationProperty";

const MainLocation = () => {
    const header = (
        <b className="text-joinet-dark">
            LOCATION CONTROL
        </b>)
    const description = (
        <>
            Location control enables you to track the whereabouts of your remote staff.
            This feature can be set to restrictive mode, requiring users to seek approval
            for connections from new countries, IP subnets, or Autonomous Systems (AS).
        </>
    )

    const description_short = (
        <>
            Location control enables you to track the whereabouts of your remote staff.
            This feature can be set to restrictive mode, requiring users to seek approval
            for new locations to connect.
        </>
    )

    const header1 = (
        <b className="text-joinet-dark">
            Flexible Access Management
        </b>)
    const description1 = (
        <>
            Access can be granted through access groups or individually and can be
            restricted by time, for instance, for a vacation or a business trip.
            Also, Joinet has a mechanism for per-user home-country identification.
        </>
    )


    const header2 = (
        <b className="text-joinet-dark">
            Third-Party VPN Detection
        </b>)
    const description2 = (
        <>
            To prevent users from bypassing the location control system by
            using public VPN services to hide their actual location, Joinet
            has built-in mechanisms for detecting and preventing third-party VPN usage.
        </>
    )
    return (
        <>
            <HeaderPart header={header} description={description} description_short={description_short}/>
            <div className="row justify-content-center mx-0 px-0 mb-5">
                <div className="col-2 d-lg-inline d-none order-lg-first"></div>
                <div className="col-lg-4 col-11 my-3 d-flex justify-content-center order-last order-lg-first">
                    <div className="col-lg-9 col-11 align-content-center ">
                        <LocationProperty photo={require("../../../Files/Location_2.png")} header={header1} description={description1} />
                        <LocationProperty photo={require("../../../Files/Location_3.png")}  header={header2} description={description2} />
                    </div>
                </div>
                <div className="col-lg-4 col-11 pt-0 pb-5  pt-4 mx-auto">
                    <img src={require("../../../Files/Location_1.png")} className="mx-auto d-block img-fluid"/>
                </div>
                <div className="col-2 d-lg-inline d-none"></div>
            </div>
        </>
    );
};

export default MainLocation;

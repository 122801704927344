import React from "react";
import i18next from "../i18n";
import MainRequests from "./MainParts/Requests/MainRequests";
import MainLocation from "./MainParts/Location/MainLocation";
import MainIntegration from "./MainParts/Integration/MainIntegration";
import MainExternalPortal from "./MainParts/MainExternalPortal";
import MainSecurity from "./MainParts/MainSecurity";


const Main = () => {

    return (
        <>
            <div >
                <div className="background-image">
                    <div className="ms-xl-5 ms-lg-5 ps-xl-5 ps-lg-5 ms-md-4 ps-md-4 ps-3 ms-3 me-4 pt-5 pb-lg-5">
                        <b className="display-5 text-joinet-blue">
                            {i18next.t('COMPREHENSIVE')} <span className="text-joinet-dark-blue">{i18next.t('APPROACH')}</span>
                            <br/>
                            {i18next.t('TO')} <span className="text-joinet-dark-blue">{i18next.t('REMOTE')} {i18next.t('ACCESS')}</span>  {i18next.t('MANAGEMENT')}
                        </b>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-10 col-11  mx-auto">
                        <img src={require("../Files/Interfaces.png")} className="mx-auto d-block pt-5 img-fluid hover-zoom-mini"/>
                    </div>

                    <div className="justify-content-center d-grid d-lg-none">
                        <a className="btn btn-sm btn-outline-secondary mt-3 mb-3"
                           href="/Docs/Joinet-Datasheet.pdf" target="_blank" rel="noreferrer">
                            Download Datasheet
                        </a>
                    </div>
                    <div className="justify-content-center d-none d-lg-grid">
                        <a className="btn btn-lg btn-outline-secondary mt-5 mb-4"
                           href="/Docs/Joinet-Datasheet.pdf" target="_blank" rel="noreferrer">
                            Download Datasheet
                        </a>
                    </div>
                </div>
            </div>
            <div className="w-100 grad-grey">
                <MainRequests />
            </div>
            <div className="mx-0 px-0 w-100">
                <MainIntegration />
            </div>
            <div className="mx-0 px-0 w-100 grad-grey">
                <MainLocation />
            </div>
            <div className="mx-0 px-0 w-100">
                <MainExternalPortal />
            </div>
            <div className="mx-0 px-0 w-100 grad-grey">
                <MainSecurity />
            </div>

        </>
    );
};

export default Main;

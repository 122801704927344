import React from "react";
import {Link, NavLink} from "react-router-dom";
import i18next from "./i18n";
import { IoMenu } from "react-icons/io5";


const Navbar = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg bg-body-tertiary grad my-0 py-0">
                <div className="container-fluid my-0 py-0">
                    <a href="/" className="navbar-brand my-0 py-0">
                        <h2 className="my-2 fw-normal text-center mx-auto">
                            <img src={require("./Files/logo.png")} className="mx-4 d-none d-md-inline" alt="..." style={{width: 50}}/>
                            <img src={require("./Files/logo.png")} className="mx-4 d-md-none d-inline" alt="..." style={{width: 36}}/>
                            <span className=" text-joinet-badge"><b>jo</b></span>
                            <span ><b className="text-joinet-badge text-light">inet</b></span>
                        </h2>
                    </a>

                    <button className="navbar-toggler border-white" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <IoMenu  color={"white"}/>
                    </button>

                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
                            <li className="nav-item mx-auto d-none">
                                <NavLink to="/platform" className="nav-link px-4  text-white mx-2 text-joinet">{i18next.t('Platform')}</NavLink></li>
                            <li className="nav-item mx-auto d-none">
                                <NavLink to="/solutions" className="nav-link px-4  text-white mx-2 text-joinet ">{i18next.t('Solutions')}</NavLink></li>
                            <li className="nav-item mx-auto">
                                <NavLink to="/licenses" className="nav-link px-4 text-white mx-2 fs-6 text-joinet">{i18next.t('Pricing')}</NavLink></li>
                            <li className="nav-item mx-auto">
                                <NavLink to="/about" className="nav-link px-4 text-white mx-2 fs-6 text-joinet">{i18next.t('About')}</NavLink></li>
                            <li className="nav-item mx-auto">
                                <NavLink to="/contacts" className="nav-link px-4 text-white  mx-2 fs-6 text-joinet">{i18next.t('Contacts')}</NavLink></li>
                        </ul>
                        <div className="justify-content-center d-flex my-1">
                            <Link to="/request" type="button" className="btn btn-outline-warning mx-2 shadow rounded-3 ">{i18next.t('Request Demo')}</Link>
                        </div>

                    </div>
                </div>
            </nav>
        </header>

    );
};

export default Navbar;

import React from "react";
import ReadMoreBtn from "../Elements/ReadMoreBtn";
import HeaderPart from "../Elements/HeaderPart";

const MainExternalPortal = () => {
    const header = (
        <b className="text-white">
            INITIAL VPN CONNECTION <span className="text-joinet-blue">& ACCESS RECOVERY</span>
        </b>)

    const description = (
        <>
            Joinet includes internet portal which allows to recover lost access for existing staff and get initial access for newcomers to corporate VPN completely remotely.
            Portal can be used for onboarding new remote staff.
            Portal available on the internet and implements multistaged authentication.
        </>)

    const description_short = (
        <>
            Joinet includes internet portal which allows to recover lost access for
            existing staff and get initial access for newcomers to corporate VPN completely remotely.
        </>
    )

    return (
        <>
            <HeaderPart header={header} description={description} description_short={description_short}/>
            <div className="col-lg-7 col-11 mx-auto pt-0 pb-5  pt-4">
                <img src={require("../../Files/External_1.png")} className="mx-auto d-block img-fluid"/>
            </div>
        </>
    );
};

export default MainExternalPortal;

import React from "react";
import IntegrationImg from "./IntegrationImg";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

const Carousel = () => {
    const time = 1400
    return (
            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item  active" data-bs-interval={time}>
                        <div className="row">
                            <IntegrationImg name="WireGuard" imgWidth={60} image={require("../../../Files/Integration-2.png")} />
                            <IntegrationImg name="OpenVPN" imgWidth={60} image={require("../../../Files/Integration-3.png")} />
                            <IntegrationImg name="Cisco" imgWidth={60} image={require("../../../Files/Integration-4.png")} />
                        </div>
                    </div>

                    <div className="carousel-item " data-bs-interval={time} >
                        <div className="row">
                            <IntegrationImg name="PaloAlto" imgWidth={60} image={require("../../../Files/Integration-5.png")} />
                            <IntegrationImg name="OpenConnect" imgWidth={60} image={require("../../../Files/Integration-6.png")} />
                            <IntegrationImg name="Fortinet" imgWidth={60} image={require("../../../Files/Integration-7.png")} />
                        </div>
                    </div>
                </div>
                <div data-bs-interval={time} hidden={true}>
                    <div className="row">
                        <IntegrationImg name="ZeroTier\nMikrotik" imgWidth={60} image={require("../../../Files/Integration-5.png")} />
                        <IntegrationImg name="L2TP" imgWidth={60} image={require("../../../Files/Integration-5.png")} />
                        <IntegrationImg name="SSTP" imgWidth={60} image={require("../../../Files/Integration-5.png")} />
                    </div>
                </div>
                <button className="carousel-control-prev justify-content-start" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <RiArrowLeftSLine size={30} />
                </button>
                <button className="carousel-control-next justify-content-end" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <RiArrowRightSLine size={30} />
                </button>
            </div>
    );
};

export default Carousel;

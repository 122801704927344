import React from "react";

const IntegrationImg = ({name, image, imgWidth}) => {

    return (
        <>
            <div className="col-2 col-lg-2 px-lg-4 mx-auto d-grid justify-content-center" >
                <div className="img-container align-content-center" style={{height: 80}}>
                    <img src={image} alt="Image" className="img-fluid "  />
                </div>
                <p className="text-secondary text-center pt-2 mt-2 d-none d-lg-inline" style={{fontSize: 15}} >{name}</p>
                <p className="text-secondary text-center pt-0 mb-0 d-inline d-lg-none" style={{fontSize: 10}} >{name}</p>
            </div>
        </>
    );
};

export default IntegrationImg;
